import React, { useState, useEffect, useContext } from "react";
import { IOrganization, IStudy } from "../types/organization";
import { IUser } from "types/user";
import AuthStorage from "utils/AuthStorage";
import { EmotionsType } from "types/facelift";
import api from "utils/api";
import { identify } from "react-fullstory";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { addDays, subDays } from "date-fns";
import { OrganizationContext } from "context/OrganizationContext";
import { useQueryClient } from "react-query";
import { getOrganizationFromURI } from "utils/facelift";

interface AppContextProps {
  loading: boolean;
  loggedIn: boolean;
  user?: IUser;
  token?: string;
  currentStudy?: IStudy;
  setCurrentStudyId?: (id: number) => void;
  logout: () => void;
  login: (username: string, password: string) => Promise<string | undefined>;
  hotstart: (hash: string) => Promise<string | undefined>;
}

interface LogingResponse {
  status: string;
  response: {
    token: string;
    user: IUser;
  };
}

export const AppContext = React.createContext<AppContextProps>({
  loading: false,
  loggedIn: false,
  user: undefined,
  token: undefined,
  logout: () => {},
  login: (username, password) => {
    return Promise.resolve(undefined);
  },
  hotstart: (username) => {
    return Promise.resolve(undefined);
  }
});

export const AppProvider: React.FC = ({ children, ...props }) => {
  const queryClient = useQueryClient();
  const orgCtx = useContext(OrganizationContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | undefined>(
    AuthStorage.getToken().token
  );
  const [user, setUser] = useState<IUser>();
  const [currentStudy, setCurrentStudy] = useState<IStudy>();

  // api();

  const loadUser = async () => {
    setLoading(true);
    const token = AuthStorage.getToken();
    if (token.token) {
      try {
        const response = await axios.get<{ status: string; response: IUser }>(
          "user",
          {
            headers: {
              Authorization: `Bearer ${token.token}`
            }
          }
        );

        if (response.data.response) {
          setUser(response.data.response);

          identify(response.data.response.id, {
            displayName: response.data.response.name,
            email: response.data.response.email
          });

          // const org = (response.data.response?.organizations ?? []).find((o) =>
          //   o.front_end_url
          //     .toLocaleLowerCase()
          //     .includes(window.location.hostname)
          // );

          const subdomain = getOrganizationFromURI();

          const org = (
            response.data.response?.organizations ?? []
          ).find((o, i, array) =>
            array.length > 0
              ? o.subdomain.toLocaleLowerCase() ===
                subdomain.toLocaleLowerCase()
              : undefined
          );

          if (org === undefined) {
            logout();
          }

          org!.studies = org!.studies.filter((s) => s.approved);

          if (org) {
            orgCtx.setOrganization(org);
          }

          if (org?.studies && org.studies.length > 0) {
            let study: IStudy | undefined;
            const studies = org.studies.sort(
              (a, b) => a.sortOrder - b.sortOrder
            );

            if (token.studyId) {
              study = studies.find((s) => s.id === token.studyId);
            }

            const st = study ?? studies[0];
            if (token && token.token) {
              console.log("load user", token.organizations);
              AuthStorage.setToken(
                token.token,
                st.id,
                token.permissions ?? [],
                token.organizations ?? [],
                addDays(new Date(), 3)
              );
            }

            setCurrentStudy(st);
          }
        }
      } catch (e) {
        console.error(`failed to get user with error: `, e);
      }
    }

    setLoading(false);
  };

  const hotstart = async (hash: string) => {
    try {
      const response = await axios.post("/hotstart", { hash });
      const token = response.data.response.token;
      const studies = response.data.response.user.organizations.studies;
      const studyId = studies && studies.length > 0 ? studies[0] : undefined;

      AuthStorage.setToken(
        token,
        studyId,
        response.data.response.user.permissions,
        response.data.response.user.organizations,
        addDays(new Date(), 3)
      );
      api();
      setToken(token);

      history.push("/");
    } catch (e) {
      console.error(`failed to login with error: `, e);

      return (e as any).errorMessage;
    }

    return undefined;
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post<LogingResponse>("auth/login", {
        email: email,
        password: password
      });
      const token = response.data.response.token;

      const subdomain = getOrganizationFromURI();

      const organizations = response.data.response?.user?.organizations ?? [];

      const org = organizations.find((o, i, array) =>
        array.length > 0
          ? o.subdomain.toLocaleLowerCase() === subdomain.toLocaleLowerCase()
          : undefined
      );

      // console.log('org', org)
      if (org?.studies) {
        org.studies = org.studies.filter((s) => s.approved);
      }

      if (org) {
        orgCtx.setOrganization(org);
      }

      const study =
        org?.studies && org.studies.length > 0 ? org.studies[0] : undefined;
      setCurrentStudy(study);

      AuthStorage.setToken(
        token,
        study ? study.id : -1,
        response.data.response.user.permissions,
        response.data.response.user.organizations.map((org) => ({
          ...org,
          studies: org.studies.filter((s) => s.approved)
        })),
        addDays(new Date(), 3),
        org?.id
      );
      setToken(token);

      const orgSettings =
        org?.settings && org.settings.length > 0 ? org.settings[0] : undefined;

      history.push(orgSettings?.default_route ?? "/");
    } catch (e) {
      console.error(`failed to login with error: `, e);

      return (e as any).errorMessage;
    }

    return undefined;
  };

  const setCurrentStudyId = (id: number) => {
    console.log(`set current study id: `, id);
    const token = AuthStorage.getToken();
    if (token && token.token) {
      AuthStorage.setToken(
        token.token,
        id,
        token.permissions ?? [],
        token.organizations ?? [],
        addDays(new Date(), 3)
      );

      queryClient.refetchQueries("buckets");

      if (orgCtx.organization?.studies) {
        const study = orgCtx.organization.studies.find((s) => s.id === id);
        if (study) {
          setCurrentStudy(study);
        }
      }
    }
  };

  const logout = () => {
    AuthStorage.removeToken();
    setToken(undefined);
    console.log("pushing to login");
    history.push("/login");
  };

  useEffect(() => {
    loadUser();
  }, [token]);

  useEffect(() => {
    let hasRemoved = AuthStorage.getCookie("REMOVED");
    const path = window.location.pathname;
    const segments = path.split("/");
    console.log("segments", segments, segments[1]);
    if (hasRemoved !== "removed" && segments[1] !== "register") {
      logout();
      AuthStorage.setHasRemoved();
    }
  }, [token]);

  return (
    <AppContext.Provider
      value={{
        loading,
        loggedIn: token != null,
        user,
        currentStudy,
        token,
        logout,
        login,
        hotstart,
        setCurrentStudyId
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const appCtx = useContext(AppContext);

  if (!appCtx) {
    throw new Error("useApp must be used within an AppProvider");
  }

  const sentimentFeatureEnabled =
    appCtx?.currentStudy?.settings.find(
      (setting) => setting.key === "sentimentEnabled"
    ) !== undefined;

  // const sentimentFeatureEnabled = false;

  const emotionFeatureEnabled =
    appCtx?.currentStudy?.groupedSettings.hasOwnProperty("emotion") === true;

  // const emotionFeatureEnabled = false;

  const emotions = (appCtx?.currentStudy?.groupedSettings["emotion"] ?? [])
    .filter((emotionSetting) => emotionSetting.value === "1")
    .map((emotionSetting) => emotionSetting.key as EmotionsType);

  return { appCtx, sentimentFeatureEnabled, emotionFeatureEnabled, emotions };
};
export default useApp;

import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import Input, { components } from "react-select";
import { Stack } from "components/Facelift/shared";
import useApp from "context/AppContext";
import useOrg from "context/OrganizationContext";
import { IStudy } from "../../../types/organization";
import { useGrid } from "hooks";

interface StudyOption {
  id: number;
  label: string;
  value: string;
  study: IStudy;
}

function StudyControls() {
  const { appCtx } = useApp();
  const org = useOrg();
  const updateStudyLoaded = useGrid((s) => s.updateStudyLoaded);

  useEffect(() => {
    console.log(`study control org: `, org);
  }, []);

  const studyOptions = useMemo(() => {
    return (org?.studies ?? [])
      .filter((s) => s.approved)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((s) => {
        return {
          id: s.id,
          label: s.name,
          value: s.name,
          study: s
        } as StudyOption;
      });
  }, [org, appCtx.user?.organizations]);

  // useEffect(() => {
  //   console.log(`study control useEffect`);
  //   const studies = (org?.studies ?? []).sort(
  //     (a, b) => a.sortOrder - b.sortOrder
  //   );
  //   if (
  //     studies.length > 0 &&
  //     appCtx.setCurrentStudyId &&
  //     appCtx.currentStudy?.id != studies[0].id
  //   ) {
  //     appCtx.setCurrentStudyId(studies[0].id);
  //   }
  // }, []);

  return (
    <Stack type="row" gap={0}>
      {studyOptions.length > 1 && (
        <Input
          isMulti={false}
          name="Studies"
          options={studyOptions}
          placeholder="Studies"
          components={{
            Option: (props) => {
              const option = props.data as StudyOption;

              return (
                <components.Option {...props}>{props.label}</components.Option>
              );
            },
            SingleValue: (props) => {
              const option = props.data as StudyOption;

              return (
                <components.SingleValue {...props}>
                  {option.label}
                </components.SingleValue>
              );
            }
          }}
          onChange={async (option) => {
            if (option) {
              updateStudyLoaded(false);

              const selectedOption = option as StudyOption;
              if (appCtx.setCurrentStudyId) {
                console.log(`study control onChange`);
                appCtx.setCurrentStudyId(selectedOption.study.id);
              }
            }
          }}
          value={
            appCtx.currentStudy
              ? [
                  {
                    id: appCtx.currentStudy.id,
                    label: appCtx.currentStudy.name,
                    value: appCtx.currentStudy.name,
                    study: appCtx.currentStudy
                  }
                ]
              : []
          }
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: "transparent",
              color: "white",
              width: "250px"
            }),
            singleValue: (styles) => ({
              ...styles,
              color: "white"
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                color: isSelected ? "white" : "black",
                backgroundColor: isSelected
                  ? "#635ec0"
                  : isFocused
                  ? "rgba(99,94,192,0.2)"
                  : "transparent",

                ":active": {
                  backgroundColor:
                    !isDisabled && (isSelected ? data.color : "#635ec0")
                }
              };
            }
          }}
        />
      )}

      {studyOptions.length == 1 && (
        <StudyNameMain>{appCtx.currentStudy?.name ?? ""}</StudyNameMain>
      )}
    </Stack>
  );
}

export default StudyControls;

const StudyNameMain = styled.p`
  font-size: 24.5px;
  color: white;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useBuckets, useResponses } from "hooks";
import { Bucket } from "types/facelift";
import { useQueryClient } from "react-query";
import { useBucketsMutation } from "hooks/useRQResponses";

const InviteInput = styled.input`
  background: white;
  font-size: 15.6px;
  letter-spacing: 0.25px;
  color: hsl(0 0% 5%);
  border: 1px solid #cdddf9;
  padding: 0.75rem 0.75rem 0.75rem 0.5rem;
  border-radius: 5px;
  appearance: none;
  width: 40px;
  position: relative;

  /* Hide number input spinners */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield; /* Firefox */

  :focus {
    border: 1px solid cornflowerblue;
  }
`;
interface Props {
  order: number | undefined | null;
  bucket: Bucket;
}
export default function BucketOrderInput({ bucket, order }: Props) {
  const queryClient = useQueryClient();
  const updateBucket = useBuckets((s) => s.updateBucketOrder);
  const update = useBuckets((s) => s.updateSelectedBucket);
  const resetResponses = useResponses((s) => s.resetResponses);
  const [inputVal, setInputVal] = useState(order);
  const { editBucketInformation } = useBucketsMutation();
  useEffect(() => {
    setInputVal(order);
  }, [order]);
  useEffect(() => {
    if (order !== inputVal) {
      if (inputVal === null || inputVal === undefined) return;
      const timeoutId = setTimeout(() => {
        // updateBucket(bucket, inputVal)
        bucket.order = inputVal;
        update(bucket);
        editBucketInformation.mutate({
          bucket: {
            ...bucket
          }
        });
        setInputVal(order);
        // queryClient.invalidateQueries(['buckets'])
        // queryClient.refetchQueries("buckets");
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [inputVal]);

  return (
    <InviteInput
      key={bucket.id}
      type="number"
      onChange={(e) => setInputVal(parseInt(e.target.value))}
      value={!inputVal || inputVal === 999999999 ? "" : inputVal}
    />
  );
}

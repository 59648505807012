import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useGrid,
  useOptions,
  useQuestions,
  useBuckets,
  useResponses
} from "hooks";
import Highlighter from "react-highlight-words";
import ReactTimeAgo from "timeago-react";
import BucketList from "./BucketList";
import { Stack } from "components/Facelift/shared";
import { getMediaInfo, subtractHoursFromDate } from "utils/facelift";
import { Response, Bucket } from "types/facelift";
import { Snapchat } from "@styled-icons/fa-brands/Snapchat";
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle";
import DefaultProfilePic from "assets/images/default-profile-black.png";
import { getMediaType } from "utils/facelift";
import ReactPlayer from "react-player";
import axios from "axios";

function handleImageError(
  event: React.SyntheticEvent<HTMLImageElement, Event>
) {
  (event.target as HTMLImageElement).src = DefaultProfilePic;
}

function UserResponse({ response }: { response: Response }) {
  const searchTerm = useGrid((s) => s.searchTerm);
  const [youtubePlaying, setYoutubePlaying] = useState(false);
  const [tikTokEmbed, setTikTokEmbed] = useState("");
  // const { isJpeg, isPng, isGif, isVideo, isAudio } = getMediaInfo(
  //   response.media_url
  // );

  const hasText = response.text.length > 0;
  // const hasMedia = isJpeg || isPng || isGif || isVideo || isAudio;
  const {
    isJpeg,
    isPng,
    isGif,
    isVideo,
    isAudio,
    hasMedia,
    hasImage,
    hasVideo,
    hasAudio,
    hasYouTubeUrl,
    hasTikTokUrl,
    hasInstagramUrl
  } = getMediaType(response.media_url);

  const getTikTokEmbed = async (url: string) => {
    try {
      const request = axios.create();
      const response = await request.get(
        `https://www.tiktok.com/oembed?url=${url}`
      );
      if (response) {
        return (
          response.data.html +
          "<style>html{overflow: hidden; background-color: white;}</style>"
        );
      } else {
        return "Sorry, something went wrong while trying to fetch the tiktok video";
      }
    } catch (error) {
      console.error("Error fetching tikTok embed", error);
      return `Sorry, something went wrong while trying to fetch the tiktok video ${error}`;
    }
  };

  useEffect(() => {
    if (hasTikTokUrl) {
      getTikTokEmbed(response.media_url).then((res) => {
        return setTikTokEmbed(res as string);
      });
    }
  }, [response]);

  return (
    <Stack
      type="column"
      gap={1}
      style={{ padding: "2rem", gridTemplateColumns: "1fr" }}
    >
      <UsernameResponseHeader response={response} />

      <Stack
        type="column"
        gap={1}
        style={{
          gridTemplateColumns: hasMedia ? (hasText ? "4fr 3fr" : "1fr") : "1fr"
        }}
      >
        {hasText && (
          <ResponseText
            searchWords={[searchTerm]}
            textToHighlight={response.text}
            highlightStyle={{
              background: "gold"
            }}
          />
        )}

        {hasMedia && (
          <Stack
            type="column"
            gap={0.25}
            style={{ padding: "0.5rem", justifyContent: "center" }}
          >
            {(isJpeg || isPng || isGif) && (
              <MediaContainer>
                <a
                  href={response.media_url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image src={response.media_url} alt="User response" />
                </a>
              </MediaContainer>
            )}
            {isVideo && (
              <MediaContainer onClick={(e) => e.stopPropagation()}>
                <Video controls>
                  <source src={response.media_url} type="video/mp4" />
                </Video>
              </MediaContainer>
            )}
            {hasYouTubeUrl ? (
              <ReactPlayer
                width={320}
                playing={youtubePlaying}
                url={response.media_url}
              />
            ) : null}
            {hasInstagramUrl ? (
              <>
                <iframe
                  id="frame"
                  style={{
                    height: "100%",
                    minHeight: "510px",
                    width: "100%",
                    overflow: "hidden"
                  }}
                  src={`https://www.instagram.com/p/${response.media_url.substring(
                    response.media_url.lastIndexOf("/") + 1
                  )}/embed`}
                ></iframe>
              </>
            ) : null}
            {hasTikTokUrl ? (
              <>
                <iframe
                  style={{
                    height: "100%",
                    minHeight: "433px",
                    width: "100%",
                    overflow: "hidden"
                  }}
                  src={`https://www.tiktok.com/embed/${response.media_url.substring(
                    response.media_url.lastIndexOf("/") + 1
                  )}`}
                />
              </>
            ) : null}
            {isAudio && (
              <MediaContainer>
                <Audio
                  controls
                  controlsList="nodownload nofullscreen noremoteplayback"
                >
                  <source src={response.media_url} type="audio/ogg" />
                </Audio>
              </MediaContainer>
            )}
          </Stack>
        )}
      </Stack>

      <BucketList response={response} textColor="black" />
    </Stack>
  );
}

export function UsernameResponseHeader({ response }: { response: Response }) {
  const selectedUser = useGrid((s) => s.selectedUser);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const resetResponses = useResponses((s) => s.resetResponses);
  const { reset } = useOptions();
  const { displayField } = useOptions();
  const displayColumn = displayField === "" ? "subtopic" : displayField;

  function handleClick() {
    console.log(`click`, selectedUser);
    console.log(`response: `, response);
    if (selectedUser?.uid === response.uid) return;

    console.log(`updating selected user: `, response);

    updateSearching(true);
    updateSelectedUser(response, true);
    updateSearchTerm("");
    reset();
    resetResponses();
    updateCurrentQuestion(null);
    updateSelectedBucket({} as Bucket);
  }

  return (
    <>
      <Stack type="row" gap={1} style={{ justifyContent: "space-between" }}>
        <Stack type="row" gap={0.75}>
          <UserDetailContainer type="row" gap={0.5} onClick={handleClick}>
            <SmallAvatarContainer
              src={response.social_profile_picture}
              onError={handleImageError}
            />
            <Stack type="row" gap={0.75}>
              <div onClick={handleClick}>
                <SmallUsername onClick={handleClick}>
                  {response.username.split(" ")[0]}
                </SmallUsername>
                <SmallDescription>
                  {response[displayColumn as keyof Response]}
                </SmallDescription>
              </div>
            </Stack>
          </UserDetailContainer>

          <Stack
            type="row"
            gap={0.25}
            style={{ marginTop: "0.25rem" }}
            className="hidden"
          >
            <TimeAgo
              datetime={subtractHoursFromDate(
                new Date(response.date.replace(/-/g, "/")),
                5
              )}
              live={false}
            />
            <Span>via</Span>
            <PlatformContainer>
              {response.platform === "facebook" ? (
                <FacebookIcon />
              ) : (
                <SnapchatIcon />
              )}
            </PlatformContainer>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default UserResponse;

const ResponseText = styled(Highlighter)`
  font-size: 14.5px;
  user-select: text;
  letter-spacing: 0.25px;
  color: inherit;
  text-align: left;
  align-self: flex-start;
`;

const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  /* min-height: 200px; */
  max-height: 275px;
  object-fit: contain;
  border-radius: 8px;
`;

const Video = styled.video`
  max-width: 250px;
  /* min-height: 200px; */
  max-height: 275px;
  /* object-fit: scale-down; */
  object-fit: contain;
  border-radius: 8px;
`;

const Audio = styled.audio`
  width: 250px;
  height: 32px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
`;

const PlatformContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  /* background: rgba(255, 255, 255, 0.8); */
  border-radius: 50%;
`;

const FacebookIcon = styled(FacebookCircle)`
  width: 100%;
  height: 100%;
  display: block;
  color: hsl(0 0% 76%);
`;

const SnapchatIcon = styled(Snapchat)`
  width: 100%;
  height: 100%;
  display: block;
  color: hsl(0 0% 76%);
`;

const UserDetailContainer = styled(Stack)`
  color: black;
  cursor: pointer;
  text-decoration: none;

  :hover {
    color: dodgerblue;
    text-decoration: underline;
  }
`;

const SmallAvatarContainer = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

const SmallUsername = styled.div`
  display: flex;
  align-items: center;
  user-select: text;
  font-size: 15px;
  letter-spacing: 0.25px;
  color: inherit;
`;

const SmallDescription = styled.div`
  user-select: text;
  font-size: 12.5px;
  letter-spacing: 0.25px;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  /* white-space: nowrap; */
  overflow: hidden;
  color: #3a3f42;
`;

const TimeAgo = styled(ReactTimeAgo)`
  color: hsl(0 0% 76%);
  font-size: 13px;
  letter-spacing: 0.25px;
`;

const Span = styled.span`
  color: hsl(0 0% 76%);
  font-size: 13px;
  letter-spacing: 0.25px;
`;

const SentimentAnalysis = styled.div`
  width: 100%;
  height: 1rem;
  background: linear-gradient(to right, #e1343d, #f9c731, #208f4c);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
`;

const AnalysisContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 75%);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

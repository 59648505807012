import React from "react";
import styled from "styled-components";
import { Stack } from "components/Facelift/shared";
import { ArrowLeft } from "@styled-icons/heroicons-outline/ArrowLeft";

function BackControls({ handleBack }: { handleBack: () => void }) {
  return (
    <Stack type="row" gap={0.5}>
      <SurveyName onClick={handleBack}>
        <ArrowIcon /> <SurveyText>Back</SurveyText>
      </SurveyName>
    </Stack>
  );
}

export default BackControls;

const ArrowIcon = styled(ArrowLeft)`
  width: 24px;
  height: 24px;
  color: white;
  display: block;
  margin-right: 0.5rem;
`;

const SurveyName = styled.span`
  display: flex;
  cursor: pointer;
`;

const SurveyText = styled.span`
  color: white;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.5px;
`;

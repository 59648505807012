import React, { useState, useEffect, useMemo } from "react";
import Dropdown from "./Dropdown";
import BucketEditModal from "components/Facelift/Modals/BucketEditModal";
import BucketShareModal from "components/Facelift/Modals/BucketShareModal";
import BucketDeleteModal from "components/Facelift/Modals/BucketDeleteModal";
import BucketCopyModal from "components/Facelift/Modals/BucketCopyModal";
import BucketRemoveShareModal from "components/Facelift/Modals/BucketRemoveShareModal";
import { Bucket } from "types/facelift";
import axios, { AxiosResponse } from "axios";
import { User } from "types/user";
import { IInvitation } from "types/invitation";
import { getOrganizationFromURI } from "utils/facelift";

interface BucketMenuProps {
  bucket: Bucket;
  isSharedBucket: boolean;
}

function BucketMenu({ bucket, isSharedBucket }: BucketMenuProps) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCopyDialogOpen, setCopyDialogOpen] = useState(false);
  const [isShareRemovalDialogOpen, setShareRemovalDialogOpen] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<IInvitation[]>();

  function stopPropagation(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.stopPropagation();
  }

  // const invitedUsers = useMemo<User[]>(() => {
  //   let invitations: User[] = [];
  //   async function invites() {
  //     const response: AxiosResponse<any> = await axios.get<any>(
  //       `invitations`
  //     );
  //     let userData: User[] = [];
  //     for (const user of response.data.response) {
  //       console.log('invite orgId', user.organization_id)
  //       let data: User = {
  //         id: user.id,
  //         email: user.email,
  //         name: "Invited User",
  //         organization: user.organization_id.toString(),
  //         organizations: [],
  //         bucket_shares: user.bucket_shares
  //       }

  //       userData.push(data)
  //     }
  //     invitations = userData
  //   }
  //   invites()
  //   return invitations
  // },[])

  useEffect(() => {
    const subdomain = getOrganizationFromURI();
    async function invites() {
      const response: AxiosResponse<any> = await axios.get<any>(`invitations`);
      let userData = [];
      for (const user of response.data.response) {
        console.log("invite orgId", user.organization_id);
        let data: IInvitation = {
          id: user.id,
          email: user.email,
          name: "Invited User",
          // organization: user.organization_id.toString(),
          organizations: user.organizations,
          bucket_shares: user.bucket_shares,
          accepted: user.accepted,
          code: user.code,
          created_at: user.created_at,
          new_user_id: user.new_user_id,
          organization_id: user.organization_id,
          updated_at: user.updated_at,
          organization: user.organization
        };
        if (user.organization.subdomain === subdomain) {
          userData.push(data);
        } else {
          continue;
        }
      }
      setInvitedUsers(userData);
    }
    if (invitedUsers === undefined || invitedUsers.length <= 0) {
      invites();
    }
  }, [invitedUsers]);

  return (
    <div onClick={stopPropagation}>
      <Dropdown
        isSharedBucket={isSharedBucket}
        isDropdownOpen={isDropdownOpen}
        setDropdownOpen={setDropdownOpen}
        setProfileDialogOpen={setProfileDialogOpen}
        setShareDialogOpen={setShareDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setCopyDialogOpen={setCopyDialogOpen}
        setShareRemovalDialogOpen={setShareRemovalDialogOpen}
      />

      <BucketEditModal
        key={`edit-${isProfileDialogOpen}`}
        isProfileDialogOpen={isProfileDialogOpen}
        setProfileDialogOpen={setProfileDialogOpen}
        bucket={bucket}
      />

      <BucketShareModal
        key={`share-${isShareDialogOpen}`}
        isShareDialogOpen={isShareDialogOpen}
        setShareDialogOpen={setShareDialogOpen}
        bucket={bucket}
        invitedUsers={invitedUsers}
      />

      <BucketDeleteModal
        isDeleteDialogOpen={isDeleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
        bucket={bucket}
      />

      <BucketCopyModal
        bucket={bucket}
        isCopyDialogOpen={isCopyDialogOpen}
        setCopyDialogOpen={setCopyDialogOpen}
      />

      <BucketRemoveShareModal
        bucket={bucket}
        isShareRemovalDialogOpen={isShareRemovalDialogOpen}
        setShareRemovalDialogOpen={setShareRemovalDialogOpen}
      />
    </div>
  );
}

export default BucketMenu;

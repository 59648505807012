import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import React, { useEffect } from "react";
import { AppProvider, OrganizationProvider } from "context";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import theme from "../../assets/styles/theme";
import { BrowserRouter as Router } from "react-router-dom";
import BaseStyles from "../../assets/styles/baseStyles";
import { ToastContainer } from "react-toastify";
import { Routes } from "./Routes";
import FullStory from "react-fullstory";
import api from "utils/api";
import CacheBuster from "react-cache-buster";
import { version } from "../../../package.json";
import SpinnerIcon from "react-spinkit";
import styled from "styled-components";

const queryClient = new QueryClient();

export const App: React.FC = () => {
  useEffect(() => {
    api();
  }, []);

  function Loading() {
    return (
      <LoadingWrapper>
        <SpinnerIcon
          name="ball-spin-fade-loader"
          color="#635ec0"
          fadeIn="none"
        />
      </LoadingWrapper>
    );
  }
  const LoadingWrapper = styled.div`
    margin-top: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Router>
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <QueryClientProvider client={queryClient}>
          <OrganizationProvider>
            <AppProvider>
              <ThemeProvider theme={theme}>
                <FullStory org="PCW6F" />
                <BaseStyles />
                <ToastContainer />

                <Routes />
              </ThemeProvider>
            </AppProvider>
          </OrganizationProvider>
        </QueryClientProvider>
      </CacheBuster>
    </Router>
  );
};

export default App;

import React from "react";
import styled from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import { Stack } from "components/Facelift/shared";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useBucketsMutation } from "hooks/useRQResponses";
import {
  AlertStack,
  Overlay,
  Grouping,
  Title,
  Description,
  SaveAction,
  Label,
  Input,
  Textarea,
  CloseIconContainer,
  ColorButton,
  FormErrorText
} from "./styled";
import { bucketColors } from "utils/facelift";
import { Bucket } from "types/facelift";

interface BucketEditModalProps {
  bucket: Bucket;
  isProfileDialogOpen: boolean;
  setProfileDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormInputs {
  name: string;
  description: string;
  color: string;
  order: number | null | undefined;
}

function BucketEditModal({
  isProfileDialogOpen,
  setProfileDialogOpen,
  bucket
}: BucketEditModalProps) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<FormInputs>({
    reValidateMode: "onBlur",
    defaultValues: {
      name: bucket.name,
      description: bucket.description,
      color: bucket.color,
      order: bucket.order
    }
  });

  const { editBucketInformation } = useBucketsMutation();

  function handleOpenChange(open: boolean) {
    setProfileDialogOpen(open);
  }

  const onSubmit: SubmitHandler<FormInputs> = (formData) => {
    editBucketInformation.mutate({
      bucket: {
        ...bucket,
        ...formData
      }
    });

    setProfileDialogOpen(false);
  };

  const currentColor = watch("color");

  return (
    <DialogRoot open={isProfileDialogOpen} onOpenChange={handleOpenChange}>
      <DialogOverlay asChild>
        <Overlay />
      </DialogOverlay>
      <DialogContent asChild>
        <AlertStack type="column" gap={1}>
          <Grouping>
            <DialogTitle asChild>
              <Title>Edit Bucket</Title>
            </DialogTitle>
            <DialogDescription asChild>
              <Description>
                Make changes to your bucket here. Click save when you are done.
              </Description>
            </DialogDescription>
          </Grouping>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              type="column"
              gap={1}
              style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
            >
              <Grouping hasErrors={errors?.order !== undefined}>
                <Stack
                  type="row"
                  gap={0}
                  style={{
                    justifyContent: "space-between"
                  }}
                >
                  <Label>Order</Label>
                  <ErrorMessage
                    errors={errors}
                    name="order"
                    render={({ message }) => (
                      <FormErrorText>{message}</FormErrorText>
                    )}
                  />
                </Stack>
                <Input
                  {...register("order", {
                    required: {
                      value: true,
                      message: "Name cannot be empty"
                    },
                    maxLength: {
                      value: 255,
                      message: `Name cannot exceed 255 characters (currently ${
                        watch("name").length
                      })`
                    }
                  })}
                />
              </Grouping>
              <Grouping hasErrors={errors?.name !== undefined}>
                <Stack
                  type="row"
                  gap={0}
                  style={{
                    justifyContent: "space-between"
                  }}
                >
                  <Label>Name</Label>
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <FormErrorText>{message}</FormErrorText>
                    )}
                  />
                </Stack>
                <Input
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Name cannot be empty"
                    },
                    maxLength: {
                      value: 255,
                      message: `Name cannot exceed 255 characters (currently ${
                        watch("name").length
                      })`
                    }
                  })}
                />
              </Grouping>

              <Grouping hasErrors={errors?.description !== undefined}>
                <Stack
                  type="row"
                  gap={0}
                  style={{
                    justifyContent: "space-between"
                  }}
                >
                  <Label>Description</Label>
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <FormErrorText>{message}</FormErrorText>
                    )}
                  />
                </Stack>
                <Textarea
                  {...register("description", {
                    maxLength: {
                      value: 1000,
                      message: `Description cannot exceed 1000 characters (currently ${
                        watch("description").length
                      })`
                    }
                  })}
                />
              </Grouping>

              <Grouping>
                <Label>Color</Label>
                <Stack type="row" gap={0.5}>
                  {bucketColors.map((color) => (
                    <ColorButton
                      key={color}
                      type="button"
                      onClick={() => setValue("color", color)}
                      style={{
                        background: color,
                        boxShadow:
                          currentColor === color
                            ? "0 0 1px 3px #635ec0"
                            : "none"
                      }}
                    />
                  ))}
                </Stack>
              </Grouping>

              <Stack type="row" gap={1} style={{ justifyContent: "flex-end" }}>
                <SaveAction type="submit">Save</SaveAction>
              </Stack>
            </Stack>
          </form>

          <DialogClose asChild>
            <CloseIconContainer type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
                <path
                  fillRule="evenodd"
                  d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </CloseIconContainer>
          </DialogClose>
        </AlertStack>
      </DialogContent>
    </DialogRoot>
  );
}

export default BucketEditModal;

const DialogRoot = styled(Dialog.Root)``;

const DialogTrigger = styled(Dialog.Trigger)``;

const DialogOverlay = styled(Dialog.Overlay)``;

const DialogContent = styled(Dialog.Content)``;

const DialogTitle = styled(Dialog.Title)``;

const DialogDescription = styled(Dialog.Description)``;

const DialogClose = styled(Dialog.Close)``;

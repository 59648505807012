import create from "zustand";
import axios, { AxiosResponse } from "axios";
import { Bucket, BucketPost } from "types/facelift";
import { useSnapshotHistoryAPI } from "hooks/useSnapshotHistory";

interface BucketProps {
  notificationBucketIds: number[];
  updateNotificationBucketIds: (bucketIds: number[]) => void;
  removeNotiticationBucketId: (bucketId: number) => void;
  combinedBuckets: Bucket[];
  updateCombinedBuckets: (buckets: Bucket[]) => void;
  selectedBucket: Bucket;
  updateSelectedBucket: (bucket: Bucket, shouldCapture?: boolean) => void;
  addResponsesToBucket: (responseIds: number[], bucket: Bucket) => void;
  removeResponseFromBucket: (responseId: number, bucketId: number) => void;
  removeResponsesFromBucket: (responseIds: number[], bucket: Bucket) => void;
  updateBucketOrder: (bucket: Bucket, order: number) => void;
}

const [useBuckets, useBucketsAPI] = create<BucketProps>((set, get) => ({
  notificationBucketIds: [],
  updateNotificationBucketIds: (bucketIds: number[]) =>
    set({ notificationBucketIds: bucketIds }),
  removeNotiticationBucketId: (bucketId: number) =>
    set({
      notificationBucketIds: get().notificationBucketIds.filter(
        (id) => id !== bucketId
      )
    }),
  combinedBuckets: [],
  updateCombinedBuckets: (buckets: Bucket[]) =>
    set({ combinedBuckets: buckets }),
  selectedBucket: {} as Bucket,
  updateSelectedBucket: async (
    bucket: Bucket,
    shouldCapture: boolean = false
  ) => {
    if (shouldCapture) useSnapshotHistoryAPI.getState().captureSnapshot();

    set({
      selectedBucket: bucket
    });
  },

  addResponsesToBucket: async (responseIds: number[], bucket: Bucket) => {
    const currentIds = bucket.posts.map(
      (bucketPost: BucketPost) => bucketPost.postId
    );

    const data = {
      name: bucket.name,
      description: bucket.description,
      color: bucket.color,
      ids: [...currentIds, ...responseIds]
    };

    await axios.patch(`/bucket/${bucket.id}`, data);
  },

  removeResponseFromBucket: async (responseId: number, bucketId: number) => {
    const data = {
      id: responseId
    };

    await axios.post(`/bucket/remove/${bucketId}`, data);
  },
  removeResponsesFromBucket: async (responseIds: number[], bucket: Bucket) => {
    const newResponsesAfterRemoval = bucket.posts
      .filter((bucketPost: BucketPost) => {
        return !responseIds.includes(bucketPost.postId);
      })
      .map((bucketPost) => bucketPost.postId);

    const data = {
      name: bucket.name,
      ids: newResponsesAfterRemoval,
      color: bucket.color || "",
      description: bucket.description || ""
    };

    await axios.patch(`/bucket/${bucket.id}`, data);
  },
  updateBucketOrder: async (bucket, order) => {
    const data = {
      name: bucket.name,
      order: order
    };

    try {
      await axios.patch(`/bucket/${bucket.id}`, data);
    } catch (error) {
      console.error("Error: ", error);
    }
  }
}));

export default useBuckets;

export { useBucketsAPI };

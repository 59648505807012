import React, { useLayoutEffect, useEffect, useRef } from "react";
import styled from "styled-components";
import { usePositioner, useMasonry } from "masonic";
import {
  useResponses,
  useGrid,
  useBuckets,
  useSize,
  useScroller,
  useGridResponses
} from "hooks";
import { ErrorBoundary } from "react-error-boundary";
import SpinnerIcon from "react-spinkit";
import useApp from "context/AppContext";
import MasonryCard from "components/Facelift/MasonryCard/Alt";
import GridHeader from "components/Facelift/GridHeader";
import * as sizes from "utils/facelift/gridMeasurements";
import { Bucket, Response } from "types/facelift";
import FallbackGrid from "./FallbackGrid";
import EmptyGrid from "./EmptyGrid";
import { GridWrapper } from "./styled";

function Grid() {
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const isStudyLoaded = useGrid((s) => s.isStudyLoaded);

  const { appCtx } = useApp();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const updateGridRef = useGrid((s) => s.updateGridRef);
  const { scrollTop, isScrolling } = useScroller(containerRef);
  const { width, height } = useSize(containerRef);

  function handleReset() {
    updateSearching(true);
    resetResponses();
    updateSelectedBucket({} as Bucket);
  }

  useEffect(() => {
    if (containerRef && containerRef.current !== null) {
      updateGridRef(containerRef.current);
    }
  }, [containerRef]);

  return (
    <ErrorBoundary FallbackComponent={FallbackGrid} onReset={handleReset}>
      <GridWrapper style={{ width: sizes.GRID_WIDTH }} ref={containerRef}>
        <GridHeader />
        {isStudyLoaded === false || appCtx.loading === true ? (
          <Loading />
        ) : (
          <NewGrid
            width={width}
            height={height}
            scrollTop={scrollTop}
            isScrolling={isScrolling}
          />
        )}
      </GridWrapper>
    </ErrorBoundary>
  );
}

// Had to create this to fix a bug related to the grid not showing after
// isStudyLoaded becomes true

function NewGrid({ width, height, scrollTop, isScrolling }: any) {
  const gridResponses = useResponses((s) => s.gridResponses);
  const selectedBucket = useBuckets((s) => s.selectedBucket);
  const isSearching = useGrid((s) => s.isSearching);

  const allResponses = useGridResponses();

  const positioner = usePositioner(
    {
      width,
      columnWidth: sizes.COLUMN_WIDTH,
      columnCount: sizes.COLUMN_COUNT,
      columnGutter: sizes.GUTTER
    },
    [isSearching]
  );

  const bucketResponses = gridResponses;
  const items =
    selectedBucket.id === undefined ? allResponses : bucketResponses;

  useEffect(() => {
    console.log(`items: `, items);
  }, [items]);

  const MasonryGrid = useMasonry({
    positioner,
    items,
    render: MasonryCard,
    height,
    scrollTop,
    isScrolling
  });

  if (items.length > 0) {
    return MasonryGrid;
  }

  return <EmptyGrid />;
}

export default Grid;

function Loading() {
  return (
    <LoadingWrapper>
      <SpinnerIcon name="ball-spin-fade-loader" color="#635ec0" fadeIn="none" />
    </LoadingWrapper>
  );
}

const LoadingWrapper = styled.div`
  margin-top: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

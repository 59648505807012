import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { useGrid, useResponses, useBuckets } from "hooks";
import { useApp } from "context";
import { addDays } from "date-fns";
import AuthStorage from "utils/AuthStorage";
import {
  addAdditionalFields,
  createTemporaryBucket,
  formatUsers,
  getOrganizationFromURI
} from "utils/facelift";
import { RawResponse, Response, Bucket, BucketPost } from "types/facelift";
import { IUser, User } from "types/user";
import { IOrganization, IStudy } from "types/organization";
import { useEffect } from "react";

async function fetchResponses() {
  const { data } = await axios.get("/social");

  return data.response;
}

async function fetchUsers() {
  const { data } = await axios.get("/users");

  return data.response;
}

async function fetchBuckets() {
  const { data } = await axios.get("/bucket");

  return data.response;
}

async function fetchStudy() {
  const { token } = AuthStorage.getToken();

  if (token === undefined) {
    throw new Error("Token is undefined.");
  }

  const { data } = await axios.get<{ response: IUser }>("user", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return data.response;
}

function useResponsesQuery() {
  const updateStudyLoaded = useGrid((s) => s.updateStudyLoaded);
  const updateGridResponses = useResponses((s) => s.updateGridResponses);

  const { data, ...rest } = useQuery<
    Array<RawResponse>,
    unknown,
    Array<Response>
  >("responses", fetchResponses, {
    select: addAdditionalFields,
    onSuccess: (data) => {
      updateGridResponses(data);
      updateStudyLoaded(true);
    },
    staleTime: Infinity
  });

  const allResponses = data ?? [];

  function resetResponses() {
    updateGridResponses(allResponses);
  }

  return { data: allResponses, resetResponses, ...rest };
}

function useBucketsQuery() {
  // const { appCtx } = useApp();
  // const queryClient = useQueryClient();
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSearching = useGrid((s) => s.updateSearching);
  const selectedBucketId = useBuckets((s) => s.selectedBucket.id);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);

  // useEffect(() => {
  //   queryClient.refetchQueries("buckets");
  // }, [appCtx.currentStudy]);

  return useQuery<Array<Bucket>>("buckets", fetchBuckets, {
    onSuccess: (buckets) => {
      if (selectedBucketId !== undefined) {
        const bucketIds = buckets.map((bucket) => bucket.id);

        if (bucketIds.includes(selectedBucketId) === false) {
          updateSearching(true);
          updateSelectedBucket({} as Bucket);
          resetResponses();
        }
      }
    }
  });
}

type AddResponseToBucketData = {
  bucketId: number;
  response: Response;
};

type ShareBucketProps = {
  bucket_id: number;
  user_ids: Array<number>;
  releaseOwnerLocked: boolean;
  invite_users?: string;
};

function useUsers() {
  const { appCtx } = useApp();

  const currentOrganizationId = appCtx.currentStudy?.org_id;
  const currentOrganization = appCtx.user?.organizations.find(
    (organization) => organization.id === currentOrganizationId
  );

  const currentOrganizationName = currentOrganization?.name ?? "Sympler";

  return useQuery<Array<IUser>, unknown, Array<User>>("users", fetchUsers, {
    select: (allUsers) => formatUsers(allUsers, currentOrganizationName),
    staleTime: Infinity
  });
}

function useBucketsMutation() {
  const queryClient = useQueryClient();
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSearching = useGrid((s) => s.updateSearching);
  const selectedBucketId = useBuckets((s) => s.selectedBucket.id);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const { appCtx } = useApp();

  const addResponseToBucket = useMutation(
    ({ bucketId, response }: AddResponseToBucketData) =>
      axios.post(`/bucket/add/${bucketId}`, {
        id:
          response.response_id && response.response_id !== undefined
            ? response.response_id
            : response.id
      }),
    {
      onMutate: async ({ bucketId, response }: AddResponseToBucketData) => {
        await queryClient.cancelQueries("buckets");

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          const indexOfBucket = previousBuckets.findIndex(
            (bucket) => bucket.id === bucketId
          );

          const updatedBuckets = [...previousBuckets];

          updatedBuckets[indexOfBucket].posts.push({
            bucketId,
            post: response,
            postId:
              response.response_id && response.response_id !== undefined
                ? response.response_id
                : response.id
          } as BucketPost);

          queryClient.setQueryData<Array<Bucket>>("buckets", updatedBuckets);
        }

        return { previousBuckets };
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  const removeResponseFromBucketMutation = useMutation(
    ({ bucketId, response }: AddResponseToBucketData) =>
      axios.post(`/bucket/remove/${bucketId}`, {
        id:
          response.response_id && response.response_id !== undefined
            ? response.response_id
            : response.id
      }),
    {
      onMutate: async ({ bucketId, response }: AddResponseToBucketData) => {
        await queryClient.cancelQueries("buckets");

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          const indexOfBucket = previousBuckets.findIndex(
            (bucket) => bucket.id === bucketId
          );

          const updatedBuckets = [...previousBuckets];

          const foundResponse = updatedBuckets[indexOfBucket].posts.filter(
            (posts) => posts.post.id === response.id
          );
          const deletedResponse = updatedBuckets[indexOfBucket].posts.filter(
            (posts) => posts.post.id !== response.id
          );

          updatedBuckets[indexOfBucket].posts = deletedResponse;

          // delete updatedBuckets[indexOfBucket].posts[response.id]
          queryClient.setQueryData<Array<Bucket>>("buckets", updatedBuckets);
        }
        return { previousBuckets };
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );
  const createBucket = useMutation(
    (data) => axios.post<{ response: Bucket; status: number }>("/bucket", data),
    {
      onMutate: async (data: {
        ids: Array<number>;
        responses: Array<Response>;
        name: string;
        description: string;
        color: string;
      }) => {
        await queryClient.cancelQueries("buckets");

        const previousBuckets =
          queryClient.getQueryData<Array<Bucket>>("buckets") ?? [];

        const newBucket = createTemporaryBucket(data, appCtx?.user?.id ?? 1);

        const updatedBuckets = [...previousBuckets, newBucket];

        queryClient.setQueryData("buckets", updatedBuckets);

        return {
          bucketId: newBucket.id,
          updatedBuckets
        };
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  const shareBucket = useMutation(
    (data: ShareBucketProps) => axios.post("/bucket/share", data),
    {
      onMutate: async ({ bucket_id, user_ids }: ShareBucketProps) => {
        await queryClient.cancelQueries("buckets");

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          const indexOfCurrentBucket = previousBuckets.findIndex(
            (previousBucket) => previousBucket.id === bucket_id
          );

          if (indexOfCurrentBucket !== -1) {
            const updatedBucket = {
              ...previousBuckets[indexOfCurrentBucket],
              shares: user_ids.map((userId) => ({
                bucketId: bucket_id,
                created_at: "",
                id: userId,
                lock: 0,
                lockexpiration: null,
                user: {
                  id: userId,
                  name: "Unknown",
                  email: "Unknown"
                },
                updated_at: "",
                userId: userId
              }))
            };

            previousBuckets[indexOfCurrentBucket] = updatedBucket;

            queryClient.setQueryData("buckets", previousBuckets);
          }
        }
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  const deleteBucket = useMutation(
    (bucketId) => axios.delete(`/bucket/${bucketId}`),
    {
      onMutate: async (bucketId: number) => {
        await queryClient.cancelQueries("buckets");

        if (selectedBucketId !== undefined) {
          updateSearching(true);
          updateSelectedBucket({} as Bucket);
          resetResponses();
        }

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          queryClient.setQueryData<Array<Bucket>>(
            "buckets",
            previousBuckets.map((bucket) => {
              if (bucket.id !== bucketId) {
                return bucket;
              }

              return {
                ...bucket,
                isDeleting: true
              };
            })
          );
        }
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  const removeSelfFromSharedBucket = useMutation(
    (bucketId) => axios.delete(`/bucket/share/delete/${bucketId}`),
    {
      onMutate: async (bucketId: number) => {
        await queryClient.cancelQueries("buckets");

        if (selectedBucketId !== undefined) {
          updateSearching(true);
          updateSelectedBucket({} as Bucket);
          resetResponses();
        }

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          queryClient.setQueryData<Array<Bucket>>(
            "buckets",
            previousBuckets.map((bucket) => {
              if (bucket.id !== bucketId) {
                return bucket;
              }

              return {
                ...bucket,
                isRemoving: true
              };
            })
          );
        }
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  const editBucketInformation = useMutation(
    ({ bucket }) =>
      axios.patch(`/bucket/${bucket.id}`, {
        name: bucket.name,
        description: bucket.description,
        color: bucket.color,
        order: bucket.order
      }),
    {
      onMutate: async ({ bucket }: { bucket: Bucket }) => {
        await queryClient.cancelQueries("buckets");

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          const indexOfCurrentBucket = previousBuckets.findIndex(
            (previousBucket) => previousBucket.id === bucket.id
          );

          if (indexOfCurrentBucket !== -1) {
            const updatedBucket = {
              ...previousBuckets[indexOfCurrentBucket],
              ...bucket
            };

            previousBuckets[indexOfCurrentBucket] = updatedBucket;

            queryClient.setQueryData("buckets", previousBuckets);
          }
        }
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  const editBucketColor = useMutation(
    ({ bucket, color }) =>
      axios.patch(`/bucket/${bucket.id}`, {
        name: bucket.name,
        description: bucket.description,
        color
      }),
    {
      onMutate: async ({
        bucket,
        color
      }: {
        bucket: Bucket;
        color: string;
      }) => {
        await queryClient.cancelQueries("buckets");

        const previousBuckets = queryClient.getQueryData<Array<Bucket>>(
          "buckets"
        );

        if (previousBuckets) {
          const indexOfCurrentBucket = previousBuckets.findIndex(
            (previousBucket) => previousBucket.id === bucket.id
          );

          if (indexOfCurrentBucket !== -1) {
            const updatedBucket = {
              ...previousBuckets[indexOfCurrentBucket],
              color
            };

            previousBuckets[indexOfCurrentBucket] = updatedBucket;

            queryClient.setQueryData("buckets", previousBuckets);
          }
        }
      },
      onSuccess: () => {
        queryClient.refetchQueries("buckets");
      }
    }
  );

  return {
    addResponseToBucket,
    createBucket,
    shareBucket,
    deleteBucket,
    removeSelfFromSharedBucket,
    editBucketInformation,
    editBucketColor,
    removeResponseFromBucketMutation
  };
}

function useUserQuery<UserSelection>(select: (data: IUser) => UserSelection) {
  return useQuery<IUser, unknown, UserSelection>("study", fetchStudy, {
    select,
    staleTime: Infinity
  });
}

function useUser() {
  return useUserQuery<IUser>((data: IUser) => data);
}

function useOrganization() {
  return useUserQuery<IOrganization | undefined>((data: IUser) => {
    const organizations = data.organizations ?? [];

    // const currentOrganization = organizations.find((organization) =>
    //   organization.front_end_url
    //     .toLocaleLowerCase()
    //     .includes(window.location.hostname)
    // );

    const subdomain = getOrganizationFromURI();

    const currentOrganization = organizations.find((o, i, array) =>
      array.length > 0
        ? o.subdomain.toLocaleLowerCase() === subdomain.toLocaleLowerCase()
        : undefined
    );

    return currentOrganization;
  });
}

function useStudy() {
  const { data: currentOrganization } = useOrganization();
  const token = AuthStorage.getToken();

  if (currentOrganization) {
    if (
      currentOrganization?.studies &&
      currentOrganization.studies.length > 0
    ) {
      const studies = currentOrganization.studies.sort(
        (a, b) => a.sortOrder - b.sortOrder
      );

      let currentStudy: IStudy | undefined;

      if (token.studyId) {
        currentStudy = studies.find((study) => study.id === token.studyId);
      }

      currentStudy = currentStudy ?? studies[0];

      if (token && token.token) {
        AuthStorage.setToken(
          token.token,
          currentStudy.id,
          token.permissions ?? [],
          token.organizations ?? [],
          addDays(new Date(), 3)
        );
      }

      return currentStudy;
    }
  }
}

export {
  useResponsesQuery,
  useBucketsQuery,
  useBucketsMutation,
  useUserQuery,
  useUser,
  useUsers,
  useOrganization,
  useStudy
};

import React, { useState, useRef } from "react";
import { useBuckets, useGrid, useQuestions, useOptions } from "hooks";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Bucket } from "types/facelift";
import { MagnifyingGlass } from "@styled-icons/heroicons-solid/MagnifyingGlass";

function SearchOverlay() {
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const searchTerm = useGrid((s) => s.searchTerm);
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateSearching = useGrid((s) => s.updateSearching);
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const selectedUser = useGrid((s) => s.selectedUser);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(searchTerm);
  const { areFiltersApplied } = useOptions();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    handleSearch();
  }

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();

    handleSearch();
  }

  function handleSearch() {
    updateSearching(true);

    if (inputValue.length > 0) {
      updateSearchTerm(inputValue, true);
    } else {
      updateSearchTerm("");
    }

    updateSelectedBucket({} as Bucket);
    setInputValue("");
  }

  const placeholder =
    currentQuestion === null
      ? selectedUser === null
        ? areFiltersApplied === true
          ? "Search filtered responses"
          : "Search all responses"
        : `Search responses from ${selectedUser.username.split(" ")[0]}`
      : `Search Question ${currentQuestion}`;

  return (
    <Container onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
      <InputContainer>
        <Input
          type="text"
          ref={inputRef}
          onChange={handleChange}
          value={inputValue}
          placeholder={placeholder}
        />

        <SearchIconContainer onClick={handleClick}>
          <SearchIcon />
        </SearchIconContainer>
      </InputContainer>
    </Container>
  );
}

export default SearchOverlay;

const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 250px;
  height: 2.25rem;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 13.5px;
  letter-spacing: 0.5px;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  color: white;
  caret-color: white;

  ::placeholder {
    color: hsl(0 0% 91%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::caret {
    width: 10px;
  }
`;

const SearchIconContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  right: 2.5%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SearchIcon = styled(MagnifyingGlass)`
  width: 32px;
  height: 32px;
  padding: 6px;
  display: block;
  color: white;
`;

import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  useGrid,
  useQuestions,
  useOptions,
  useResponses,
  useBuckets,
  useToasts
} from "hooks";
import { useBucketsQuery } from "hooks/useRQResponses";
import { Stack } from "components/Facelift/shared";
import hexToRgba from "hex-to-rgba";
import { v4 as uuid } from "uuid";
import { toDataURL, getBucketsForResponse } from "utils/facelift";
import { Copy } from "@styled-icons/boxicons-solid/Copy";
import { Download } from "@styled-icons/boxicons-solid/Download";
import { CheckCircle } from "@styled-icons/boxicons-regular/CheckCircle";
import { DotsHorizontalRounded } from "@styled-icons/boxicons-regular/DotsHorizontalRounded";
import { Trash } from "@styled-icons/boxicons-regular/Trash";
import { CommentDiscussion } from "@styled-icons/octicons/CommentDiscussion";
import { Response, Bucket, Toast } from "types/facelift";
import { useBucketsMutation } from "hooks/useRQResponses";

interface CardControlsProps {
  hasText: boolean;
  hasMedia: boolean;
  response: Response;
  hasDeleted: boolean;
  setHasDeleted: React.Dispatch<React.SetStateAction<boolean>>;
}

function CardControls({
  hasText,
  hasMedia,
  response,
  hasDeleted,
  setHasDeleted
}: CardControlsProps) {
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const selectedUser = useGrid((s) => s.selectedUser);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const addToast = useToasts((s) => s.addToast);
  const { reset } = useOptions();

  const [showingControls, setShowingControls] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [hasDownloaded, setHasDownloaded] = useState(false);

  const { data } = useBucketsQuery();
  const responseBuckets = getBucketsForResponse(response.id, data);

  const removeResponseFromBucket = useBuckets(
    (s) => s.removeResponseFromBucket
  );
  const selectedBucket = useBuckets((s) => s.selectedBucket);

  const { removeResponseFromBucketMutation } = useBucketsMutation();

  async function handleResponseClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.stopPropagation();

    console.log(`clipboard: `, navigator.clipboard);

    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(response.text);
      } else {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.setAttribute("value", response.text);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
      }

      const toast: Toast = {
        id: uuid(),
        type: "add",
        message: <ToastText>Copied text successfully</ToastText>
      };

      addToast(toast);
    } catch (err) {
      const toast: Toast = {
        id: uuid(),
        type: "remove",
        message: <ToastText>Unable to copy text at this time</ToastText>
      };

      addToast(toast);
    }
  }

  async function handleResponseDownload(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.stopPropagation();

    try {
      console.log(
        `creating download link: ${response.media_url} - ${response.uid}`
      );
      const a = document.createElement("a");
      const fileExt = response.media_url.split(".").pop();
      const data = await toDataURL(response.media_url);
      a.href = data;
      // if (data === '') {
      //   a.href = response.media_url
      //   a.target = '_blank'
      // } else {
      //   a.href = data
      // }
      a.download = `${response.uid}-${response.question_no}.${fileExt}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setHasDownloaded(true);
    } catch (err) {
      console.error(err);
    }
  }

  function handleUsernameClick() {
    if (selectedUser?.uid === response.uid) return;

    updateSearching(true);
    updateSelectedUser(response, true);
    updateSearchTerm("");
    reset();
    resetResponses();
    updateCurrentQuestion(null);
    updateSelectedBucket({} as Bucket);
  }

  function handleRemoveRowFromThisBucket() {
    console.log("Janitha 129: " + response.id + " " + selectedBucket.id);
    removeResponseFromBucket(response.id, selectedBucket.id);
  }

  function handleRemoveRowFromThisBucketUsingMutation() {
    console.log("Janitha 136: " + response.id + " " + selectedBucket.id);
    removeResponseFromBucketMutation.mutate({
      bucketId: selectedBucket.id,
      response: response
    });
  }

  const bucketColor =
    responseBuckets.length > 0 ? responseBuckets[0].color : "#635ec0";

  const rgbaColor = hexToRgba(bucketColor, "0.65");
  const hoverColor = hexToRgba(bucketColor, "0.05");

  const isBucketView = selectedBucket.id !== undefined;

  return (
    <DropdownRoot>
      <DropdownTrigger asChild>
        <ResponseControlsIconContainer
        // animate={{ rotate: showingControls ? -90 : 0 }}
        >
          <ResponseControlsIcon
            style={{ color: rgbaColor }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </ResponseControlsIconContainer>
      </DropdownTrigger>
      <DropdownContent asChild align="end">
        <ResponseControlsDropdown type="column" gap={0}>
          {hasText && (
            <DropdownItem asChild>
              <ResponseControl
                key={`${hasCopied}-text`}
                hoverColor={hoverColor}
                onClick={handleResponseClick}
              >
                <ResponseControlText>
                  Copy text to clipboard
                </ResponseControlText>

                <ResponseControlsIconContainer
                  style={{ width: 20, height: 20 }}
                >
                  <CopyIcon style={{ color: rgbaColor }} />
                </ResponseControlsIconContainer>
              </ResponseControl>
            </DropdownItem>
          )}

          {hasMedia && (
            <DropdownItem asChild>
              <ResponseControl
                key={`${hasDownloaded}-media`}
                hoverColor={hoverColor}
              >
                <a
                  href={response.media_url}
                  download
                  style={{
                    display: "flex",
                    width: "100%",
                    textDecoration: "none"
                  }}
                >
                  <ResponseControlText>
                    {hasDownloaded ? "Downloaded" : "Download media"}
                  </ResponseControlText>
                  <ResponseControlsIconContainer
                    style={{ width: 20, height: 20 }}
                  >
                    {hasDownloaded ? (
                      <SuccessIcon style={{ color: rgbaColor }} />
                    ) : (
                      <DownloadIcon style={{ color: rgbaColor }} />
                    )}
                  </ResponseControlsIconContainer>
                </a>
              </ResponseControl>
            </DropdownItem>
          )}

          <DropdownItem asChild>
            <ResponseControl
              key={`${hasDownloaded}-media`}
              hoverColor={hoverColor}
              onClick={handleUsernameClick}
            >
              <ResponseControlText>
                See all responses from {response.username.split(" ")[0]}
              </ResponseControlText>
              <ResponseControlsIconContainer style={{ width: 20, height: 20 }}>
                <ResponsesIcon style={{ color: rgbaColor }} />
              </ResponseControlsIconContainer>
            </ResponseControl>
          </DropdownItem>

          {isBucketView && (
            <DropdownItem asChild>
              <ResponseControl
                key={`${hasDownloaded}-media`}
                hoverColor={hoverColor}
                onClick={handleRemoveRowFromThisBucketUsingMutation}
              >
                <ResponseControlText>
                  Remove this response from this bucket
                </ResponseControlText>
                <ResponseControlsIconContainer
                  style={{ width: 20, height: 20 }}
                >
                  <TrashIcon style={{ color: rgbaColor }} />
                </ResponseControlsIconContainer>
              </ResponseControl>
            </DropdownItem>
          )}
        </ResponseControlsDropdown>
      </DropdownContent>
    </DropdownRoot>
  );
}

export default CardControls;

const ResponseControlsIconContainer = styled(motion.div)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 50%;
  /* background: rgba(135, 129, 129, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  appearance: none !important;
  /* :hover {
    box-shadow: var(--shadow);
  } */
`;

const ResponseControlsIcon = styled(DotsHorizontalRounded)`
  width: 100%;
  height: 100%;
  display: block;
  color: #635ec0;
`;

const ResponseControlsDropdown = styled(Stack)`
  border-radius: 5px;
  background: white;
  box-shadow: var(--shadow);
  overflow: hidden;
`;

const ResponseControl = styled(motion.div)<{ hoverColor: string }>`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  background: inherit;
  transition: background 0.1s ease-in-out;
  display: flex;
  align-items: center;

  :hover {
    background: ${(p) => p.hoverColor};
  }
`;

const ResponseControlText = styled.div`
  color: hsl(0 0% 15%);
  min-width: 22ch;
  letter-spacing: 0.5px;
  font-size: 13.5px;
  white-space: nowrap;
  text-align: right;
  margin-right: 0.75rem;
  width: 100%;
`;

const CopyIcon = styled(Copy)`
  width: 100%;
  height: 100%;
  display: block;
`;

const DownloadIcon = styled(Download)`
  width: 100%;
  height: 100%;
  display: block;
`;

const SuccessIcon = styled(CheckCircle)`
  width: 100%;
  height: 100%;
  display: block;
  transform: scale(1.05);
`;

const TrashIcon = styled(Trash)`
  width: 100%;
  height: 100%;
  display: block;
`;

const ResponsesIcon = styled(CommentDiscussion)`
  width: 100%;
  height: 100%;
  display: block;
`;

const DropdownRoot = styled(DropdownMenu.Root)``;

const DropdownTrigger = styled(DropdownMenu.Trigger)`
  z-index: 1000000;
`;

const DropdownContent = styled(DropdownMenu.Content)``;

const DropdownItem = styled(DropdownMenu.Item)``;

const ToastText = styled.div`
  font-size: 14px;
  color: white;
  letter-spacing: 0.75px;
`;

/*
         <AnimatePresence exitBeforeEnter initial={false}>
            {true && (
              <ResponseControl
                key={`${hasDeleted}-media`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                hoverColor={hoverColor}
                onClick={handleResponseDelete}
              >
                <ResponseControlText>
                  {hasDeleted ? "Removed" : "Remove response"}
                </ResponseControlText>
                <ResponseControlsIconContainer
                  style={{ width: 20, height: 20 }}
                >
                  {hasDeleted ? (
                    <SuccessIcon style={{ color: rgbaColor }} />
                  ) : (
                    <TrashIcon style={{ color: rgbaColor }} />
                  )}
                </ResponseControlsIconContainer>
              </ResponseControl>

            )}
          </AnimatePresence> 
*/

import { addDays, subDays } from "date-fns";
import { IUserPermission } from "../types/user";
import { IOrganization } from "../types/organization";
import { getOrganizationFromURI } from "./facelift";

export default class AuthStorage {
  private static cookieName = "TOKEN";
  private static studyName = "STUDY";
  private static permissionsName = "PERMISSIONS";
  private static organizationsName = "ORGANIZATIONS";
  private static hasRemovedCookie = "REMOVED";
  private static orgIdName = "ORGID";

  private static domain() {
    let domain = window.location.hostname;
    // const index = domain.indexOf(".") + 1;
    // domain = domain.substr(index, domain.length - index);
    if (!domain.includes("localhost")) {
      domain = `.${domain}`;
    }

    return domain;
  }

  static removeToken() {
    const domain = AuthStorage.domain();
    document.cookie = `${
      AuthStorage.studyName
    }=;domain=${domain};path=/;expires=${subDays(
      new Date(),
      365
    ).toUTCString()}`;
    document.cookie = `${
      AuthStorage.cookieName
    }=;domain=${domain};path=/;expires=${subDays(
      new Date(),
      365
    ).toUTCString()}`;
    document.cookie = `${
      AuthStorage.permissionsName
    }=;domain=${domain};path=/;expires=${subDays(
      new Date(),
      365
    ).toUTCString()}`;
    document.cookie = `${
      AuthStorage.organizationsName
    }=;domain=${domain};path=/;expires=${subDays(
      new Date(),
      365
    ).toUTCString()}`;
    document.cookie = `${
      AuthStorage.hasRemovedCookie
    }=;domain=${domain};path=/;expires=${subDays(
      new Date(),
      365
    ).toUTCString()}`;
    document.cookie = `${
      AuthStorage.orgIdName
    }=;domain=${domain};path=/;expires=${subDays(
      new Date(),
      365
    ).toUTCString()}`;
  }

  static setHasRemoved() {
    const domain = AuthStorage.domain();
    document.cookie = `${
      AuthStorage.hasRemovedCookie
    }=removed;domain=${domain};path=/;expires=${addDays(
      new Date(),
      365
    ).toUTCString()}`;
  }

  static setToken(
    token: string,
    studyId: number,
    permissions: IUserPermission[],
    organizations: IOrganization[],
    expiration: Date,
    orgId?: number
  ) {
    const domain = AuthStorage.domain();
    const permissionsStr = JSON.stringify(permissions);
    let orgs = organizations.map((o) => ({
      id: o.id,
      subdomain: o.subdomain,
      front_end_url: o.front_end_url,
      currentStudy: o.currentStudy,
      name: o.name,
      icon: o.icon,
      color: o.color
    }));
    const organizationsStr = JSON.stringify(orgs);
    // console.log('org check', orgs)
    // console.log('org check2 organizations', organizationsStr)

    document.cookie = `${
      AuthStorage.cookieName
    }=${token};domain=${domain};path=/;expires=${expiration.toUTCString()}`;
    document.cookie = `${
      AuthStorage.studyName
    }=${studyId};domain=${domain};path=/;expires=${expiration.toUTCString()}`;
    document.cookie = `${
      AuthStorage.permissionsName
    }=${permissionsStr};domain=${domain};path=/;expires=${expiration.toUTCString()}`;
    document.cookie = `${
      AuthStorage.organizationsName
    }=${organizationsStr};domain=${domain};path=/;expires=${expiration.toUTCString()}`;
    if (orgId) {
      document.cookie = `${
        AuthStorage.orgIdName
      }=${orgId};domain=${domain};path=/;expires=${expiration.toUTCString()}`;
    }
  }

  public static getCookie(cname: string) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return undefined;
  }

  static getToken() {
    const token = AuthStorage.getCookie(AuthStorage.cookieName);
    const studyId = AuthStorage.getCookie(AuthStorage.studyName);
    const permissionStr = AuthStorage.getCookie(AuthStorage.permissionsName);
    const organizationsStr = AuthStorage.getCookie(
      AuthStorage.organizationsName
    );
    const orgIdName = AuthStorage.getCookie(AuthStorage.orgIdName);

    const organizations =
      organizationsStr && organizationsStr.length > 0
        ? (JSON.parse(organizationsStr) as IOrganization[] | undefined)
        : undefined;

    const subdomain = getOrganizationFromURI();
    // const orgId = (organizations ?? []).find((o, index, array) =>
    //   array.length > 0
    //     ? o?.subdomain?.toLocaleLowerCase() === subdomain?.toLocaleLowerCase()
    //     : undefined
    // )?.id;
    let orgId;
    if (organizations && organizations.length > 0) {
      organizations.find((o) => {
        o?.subdomain?.toLocaleLowerCase() === subdomain?.toLocaleLowerCase();
      });
    }
    if (!orgId) {
      orgId = orgIdName;
    }
    // if (!orgId) {
    //   AuthStorage.removeToken();
    // }

    return {
      token: token && token.length > 0 ? token : undefined,
      studyId: studyId ? Number(studyId) : undefined,
      permissions:
        permissionStr && permissionStr.length > 0
          ? (JSON.parse(permissionStr) as IUserPermission[] | undefined)
          : undefined,
      organizations,
      orgId
    };
  }
}

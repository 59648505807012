import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "hooks";
import { useBucketsQuery, useBucketsMutation } from "hooks/useRQResponses";
import { getBucketColor, getBucketsForResponse } from "utils/facelift";
import { Stack } from "components/Facelift/shared";
import hexToRgba from "hex-to-rgba";
import ResponseBucket from "./ResponseBucket";
import { Response, Bucket } from "types/facelift";
import { SubdirectoryArrowRight } from "@styled-icons/material/SubdirectoryArrowRight";

interface BucketListProps {
  response: Response;
  textColor?: string;
}

function BucketList({ response, textColor = "white" }: BucketListProps) {
  const [inputText, setInputText] = useState("");
  const [showingBucketDropdown, setShowingBucketDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(dropdownRef, hideBucketDropdown);

  const { addResponseToBucket, createBucket } = useBucketsMutation();

  function showBucketDropdown() {
    setShowingBucketDropdown(true);
  }

  function hideBucketDropdown() {
    setShowingBucketDropdown(false);
  }

  async function handleResponseBucketClick(bucket: Bucket) {
    setInputText("");
    hideBucketDropdown();

    addResponseToBucket.mutate({
      bucketId: bucket.id,
      response
    });
  }

  async function handleBucketCreation() {
    const color = getBucketColor();

    createBucket.mutate({
      ids: [response.id],
      name: inputText,
      responses: [response],
      description: "",
      color
    });

    setInputText("");
    hideBucketDropdown();
  }

  const { data } = useBucketsQuery();
  const buckets = data ?? [];

  const responseBuckets = getBucketsForResponse(response.id, data);

  const bucketsToShow = buckets.filter((bucket: Bucket) => {
    const bucketIds = responseBuckets.map((respBucket) => respBucket.id);

    if (bucketIds.includes(bucket.id)) {
      return false;
    }

    return bucket.name.toLowerCase().startsWith(inputText.toLowerCase());
  });

  return (
    <FlexStack
      className="hidden"
      style={{
        background: hexToRgba("#cccccc", "0.05")
      }}
    >
      {responseBuckets.map((bucket) => (
        <ResponseBucket key={bucket.id} bucket={bucket} response={response} />
      ))}

      <BucketInputContainer data-value={inputText}>
        <BucketInput
          type="text"
          placeholder="Start typing to add/create buckets"
          // size={18}
          textColor={textColor}
          value={inputText}
          maxLength={20}
          onChange={(e) => setInputText(e.target.value)}
          onFocus={showBucketDropdown}
        />
        {showingBucketDropdown && (
          <BucketsDropdownContainer ref={dropdownRef}>
            <Stack type="column" gap={0.5}>
              {inputText.length > 0 && (
                <HeaderText>To create new bucket</HeaderText>
              )}

              {inputText.length > 0 && (
                <Stack type="row" gap={0.25}>
                  <IconContainer>
                    <ArrowIcon />
                  </IconContainer>
                  <ResponseBucketWrapper
                    style={{ background: "#635ec0" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBucketCreation();
                    }}
                  >
                    <ResponseBucketText>{inputText}</ResponseBucketText>
                  </ResponseBucketWrapper>
                </Stack>
              )}

              {bucketsToShow.length > 0 && (
                <HeaderText>To add to existing bucket</HeaderText>
              )}

              {bucketsToShow
                .sort((a, b) => {
                  let aVal = a.order;
                  let bVal = b.order;
                  if (
                    aVal !== null &&
                    aVal !== undefined &&
                    bVal !== null &&
                    bVal !== undefined
                  ) {
                    if (aVal > bVal) {
                      return 1;
                    } else if (aVal < bVal) {
                      return -1;
                    } else if (aVal === bVal) {
                      return 0;
                    } else {
                      return -1;
                    }
                  } else {
                    return -1;
                  }
                })
                .map((bucket, i) => (
                  <Stack type="row" gap={0.25} key={bucket.id}>
                    <IconContainer>
                      <ArrowIcon />
                    </IconContainer>
                    <ResponseBucketWrapper
                      style={{ background: bucket.color }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleResponseBucketClick(bucket);
                      }}
                    >
                      <ResponseBucketText>{bucket.name}</ResponseBucketText>
                    </ResponseBucketWrapper>
                  </Stack>
                ))}

              {buckets.length === 0 && inputText.length === 0 && (
                <ResponseBucketText
                  style={{ color: "hsl(0 0% 8%)", textTransform: "none" }}
                >
                  Enter name of your first bucket
                </ResponseBucketText>
              )}
            </Stack>
          </BucketsDropdownContainer>
        )}
      </BucketInputContainer>
    </FlexStack>
  );
}

export default BucketList;

const FlexStack = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
  padding: 4px;
  margin: -0.25rem;

  & > * {
    margin: 0.25rem;
  }
`;

const BucketInputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  flex: 1;
  min-width: 125px;

  /* &::after,
  input {
    width: auto;
    min-width: 1em;
    grid-area: 1 / -1;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  }

  &::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
  } */
`;

const BucketInput = styled.input<{ textColor: string }>`
  appearance: none;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 12.5px;
  letter-spacing: 0.5px;
  color: ${(p) => (p.textColor === "white" ? "hsl(0 0% 95%)" : "hsl(0 0% 5%)")};
  padding: 4px 12px 4px 12px;

  ::placeholder {
    color: hsl(0 0% 75%);
  }
`;

const BucketsDropdownContainer = styled.div`
  background: white;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 1rem;
  box-shadow: var(--shadow);
  transform: translateY(105%);
  z-index: 5;
`;

const ResponseBucketWrapper = styled.div`
  width: min-content;
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.div`
  white-space: nowrap;
  color: hsl(0 0% 8%);
  justify-self: flex-start;
  font-size: 13px;
  letter-spacing: 0.25px;
  margin-bottom: 0.2rem;
`;

const ResponseBucketText = styled.div`
  color: white;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.75px;
`;

const BucketHeader = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 12.5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  margin-right: 0.5rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
`;

const ArrowIcon = styled(SubdirectoryArrowRight)`
  width: 100%;
  height: 100%;
  color: #635ec0;
  display: block;
`;
